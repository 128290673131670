import { AnalyticsEventType } from '../../enums';
import { BaseEvent } from './base-event';

declare let dataLayer: Array<any>;

export class GoogleEvent extends BaseEvent {
  private getEvent(): string {
    switch (this.partakeEvent?.type) {
      case AnalyticsEventType.PAGE_VIEW:
        return 'pageview';
      default:
        return this.partakeEvent?.type;
    }
  }

  convertEvent() {
    const event = this.getEvent();
    if (event) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { url, type, user, ...data } = this.partakeEvent;
      this.convertedEvent = {
        event,
        url,
        ...data
      };
    }
  }

  track() {
    if (this.convertedEvent && window['dataLayer']) {
      dataLayer.push(this.convertedEvent);
    }
  }
}
