import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from '../models';

@Pipe({
  name: 'menuItem'
})
export class MenuItemPipe implements PipeTransform {
  transform(
    categories: { id: string; name: string; type: string; items: MenuItem[] }[],
    filter: {
      type?: string;
      category?: string;
      name?: string;
    }
  ): { id: string; name: string; type: string; items: MenuItem[] }[] {
    if (!filter || !filter.type && !filter.category) {
      return categories;
    }

    if (filter.name) {
      return categories.filter((category) => {
        return !!category.items.find((menuItem) => {
          return menuItem.data.name?.indexOf(filter.name) > -1;
        });
      });
    }
    if (filter.category) {
      return categories.filter((category) => {
        return category.name === filter.category;
      });
    }
    if (filter.type) {
      return categories.filter((category) => {
        return category.type === filter.category;
      });
    }
    return categories;
  }

}
