import _ from 'lodash';
import moment from 'moment';
import { GameType, GameStatus } from '../enums';
import { CompetitionData, GameData, GameEntryData } from '../models';

export interface GameDataAggregation {
  games: GameData[];
  info?: GameData;
  competition?: CompetitionData;
  type?: GameType;
  date?: string;
  status?: GameStatus;
  totalScore?: number;
  entries?: number;
}

export const baseQuery = {
  limit: 100, status: 'pre-open,open,live'
};

export function getHighlightedGames(games: GameData[]): GameData[] {
  const highlighted = games.filter((game) => game.isHighlighted);

  highlighted.sort((gameA: GameData, gameB: GameData) => gameB.prizePool - gameA.prizePool);

  return highlighted;
}

export function getMainGame(games: GameData[]): GameData {
  return games.find((game: GameData) => game.isHighlighted);
}

export function getMiniGames(games: GameData[]): GameData[] {
  return games.filter((a: GameData) => a.isMiniGame);
}

export function getActiveMiniGames(games: GameData[]): GameData[] {
  return games.filter((a: GameData) => a.isMiniGame && a.status === GameStatus.OPEN);
}

export function getGamesByType(games: GameData[], type: GameType): GameData[] {
  return games.filter((game: GameData) => game.type === type);
}

export function getGamesNotEqualToType(games: GameData[], type: GameType): GameData[] {
  return games.filter((game: GameData) => game.type !== type);
}

export function getBonusPoints(games: GameData[]): number {
  return games.reduce((points: number, game: GameData & { myEntry: { totalScore: number } }) => {
    if (game.myEntry) {
      points += game.myEntry.totalScore;
    }
    return points;
  }, 0);
}

export function getCompletedMiniGames(games: GameData[]): number {
  return games.reduce((count: number, game: any) => {
    if (game.myEntry) {
      count++;
    }
    return count;
  }, -1);
}

/**
 * @description
 * Take all games in array and organize into an array of competition ids with sub games
 */
export function organizeGamesByCompetition(games: GameData[]): GameDataAggregation[] {
  const gamesByCompetition = games.reduce((prev: any, game: GameData) => {
    const competitionId = game.competitionId as CompetitionData;
    if (!competitionId) {
      return prev;
    }
    if (!prev[competitionId._id]) {
      prev[competitionId._id] = {
        competition: game.competitionId,
        games: [],
        entries: 0,
        view: false
      };
    }
    prev[competitionId._id].games.push(game);
    prev[competitionId._id].entries += game.entries;
    return prev;
  }, {});
  return _.values(gamesByCompetition);
}

/**
 * @description
 * Take all games in array and organize into an array of game types with sub games
 */
export function organizeGamesByType(games: GameData[]): GameDataAggregation[] {
  const gamesByCompetition = games.reduce((prev: any, game: GameData) => {
    if (!prev[game.type]) {
      prev[game.type] = {
        type: game.type,
        games: [],
        entries: 0,
        view: false
      };
    }
    prev[game.type].games.push(game);
    prev[game.type].entries += game.entries;
    return prev;
  }, {});
  return _.values(gamesByCompetition);
}

/**
 * @description
 * Take all games in array and organize into an array of game types with sub games
 */
export function organizeGamesByGroup(games: GameData[]): GameDataAggregation[] {
  const gamesByGroup = games.reduce((prev: any, game: GameData) => {
    const groups = game.groups;
    if (!groups) {
      return prev;
    }
    groups.forEach((group) => {
      if (group.isHighlighted) {
        return;
      }
      if (!prev[group._id]) {
        prev[group._id] = {
          info: group,
          status: group.status,
          games: [],
          entries: 0,
          totalScore: 0
        };
      }
      prev[group._id].games.push(game);
      prev[group._id].entries += game.entries;
      if (game.myEntry) {
        prev[group._id].totalScore = game.myEntry.totalScore;
      }
    });
    return prev;
  }, {});
  return _.values(gamesByGroup);
}

export function groupGamesByDate(games: GameData[], dateProperty = 'openDate'): GameDataAggregation[] {
  const gamesByDate = games.reduce((prev: any, game: any) => {
    const sortDate = _.get(game, dateProperty) || _.get(game, 'openDate');
    const dateString = moment(sortDate).format('yyyy-MM-DD');
    if (!prev[dateString]) {
      prev[dateString] = {
        date: dateString,
        games: [],
        entries: 0,
        status: game.status,
        totalScore: 0,
      };
    }
    prev[dateString].games.push(game);
    prev[dateString].entries += game.entries;
    if (game.myEntry) {
      prev[dateString].totalScore += game.myEntry.totalScore;
    }
    return prev;
  }, {});
  return (_.values(gamesByDate) || [])
    .sort((a: GameDataAggregation, b: GameDataAggregation) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
}

export function getEntryForGameType(type: GameType): GameEntryData {
  switch (type) {
    case GameType.TRIVIA:
      return {
        gameId: null,
        userId: null,
        cashPrize: 0,
        partakeCashPrize: 0,
        nonCashPrize: null,
        settled: false,
        selections: [],
        totalScore: 0
      } as any;
    case GameType.HEADS_UP:
      return {
        gameId: null,
        userId: null,
        cashPrize: 0,
        partakeCashPrize: 0,
        nonCashPrize: null,
        settled: false,
        selections: [],
        tieBreaker: {
          answer: null
        },
        totalScore: 0
      } as any;
    case GameType.PROPS:
      return {
        gameId: null,
        userId: null,
        cashPrize: 0,
        partakeCashPrize: 0,
        nonCashPrize: null,
        settled: false,
        selections: [],
        totalScore: 0
      } as any;
    case GameType.BONUS_ACTION:
      return {
        gameId: null,
        userId: null,
        cashPrize: 0,
        partakeCashPrize: 0,
        nonCashPrize: null,
        settled: false,
        formResponse: [],
        isVerified: false,
        totalScore: 0
      } as any;
  }
  return null;
}
