import { AbstractModel } from '../abstract.model';
import {
  DeliveryLocationType,
  DiningOptionsBehavior,
  PickupLocationType,
  RequiredGuestFields,
  ServiceChargeBehavior
} from '../../enums';


export interface IAsapSchedule {
  availableNow: boolean;
  availableAt: Date;
  intervalMin: number;
  intervalMax: number;
}

export interface IFutureFulfillmentTime {
  time: Date;
  timeString: string; // HH:MM
  tz: string; // EST
}

export interface IFutureScheduleDate {
  date: string;
  timesAndGaps: IFutureFulfillmentTime[];
}

export interface IFutureScheduleTimeGapConfig {
  daysOfWeek: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  timeGapDaysAhead: number;
  skipTimeRequirement: boolean;
  minDaysBefore: number;
  timeGapMinutes: number;
  timeGapStartHour: number;
  timeGapStartMinute: number;
  timeGapEndHour: number;
  timeGapEndMinute: number;
}

export interface IFutureSchedule {
  exclusionDates: Date[];
  dates: IFutureScheduleDate[];
  timeGapConfig: IFutureScheduleTimeGapConfig;
}

export interface IDeliveryOption {
  name: string;
  options: IDeliveryOption[];
}

export interface IDeliveryOptions {
  name: string;
  type: DeliveryLocationType;
  // For now this property won't be used until a delivery integration is provided
  deliveryProvider: string;
  options: IDeliveryOption[];
}

export interface IPickupLocation {
  name: string;
  locationType: PickupLocationType;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: number;
    formatted: string;
  };
  description: string;
}

export interface ServiceChargeData {
  _id: string;
  name: string;
  behavior: ServiceChargeBehavior;
  value: number;
  isPartake: boolean;
  isTenderCharge: boolean;
}

export interface DiningOptionData {
  _id: string;
  name: string;
  displayName: string;
  behaviour: DiningOptionsBehavior;
  asapSchedule: IAsapSchedule;
  futureSchedule: IFutureSchedule;
  deliveryOptions: IDeliveryOptions;
  pickupLocation: IPickupLocation;
  restaurant: string;
  requiredGuestFields: RequiredGuestFields[];
  serviceCharges: [];
  account: string;
  isActive: boolean;
  posOnly: boolean;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

/**
 * @todo - Remove patch once all locations have correct info
 */
export class DiningOption extends AbstractModel<DiningOptionData> {
  static readonly NOW_BEHAVIOURS = [
    DiningOptionsBehavior.FAST_CLOSE,
    DiningOptionsBehavior.TAB,
    DiningOptionsBehavior.TABLE,
    DiningOptionsBehavior.EVENT,
  ];

  constructor(public data: DiningOptionData) {
    super(data);
  }

  /**
   * @description
   * The only information which is auto collected is a tab and the guest name from the card.
   * If Email or Phone exists, then this information is collected at the time of selecting Tab
   */
  get autoCollectsInformation() {
    if (this.data.behaviour === DiningOptionsBehavior.TAB) {
      if (this.data.requiredGuestFields.includes(RequiredGuestFields.EMAIL)) {
        return false;
      }
      if (this.data.requiredGuestFields.includes(RequiredGuestFields.PHONE)) {
        return false;
      }
      return true;
    }
    return false;
  }

  get canCreateTab() {
    return [
      DiningOptionsBehavior.FAST_CLOSE,
      DiningOptionsBehavior.TAB,
      DiningOptionsBehavior.TABLE,
      DiningOptionsBehavior.EVENT,
    ].includes(this.data.behaviour);
  }
}
