import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'classification'
})
export class ClassificationPipe implements PipeTransform {

  transform(classifications: Array<any>, filter: Array<string>): Array<any> {
    const filtered = [];
    classifications.forEach(classification => {
      filter.forEach(val => {
        if (val === classification.type) {
          filtered.push(classification);
        }
      });
    });
    return filtered;
  }

}
