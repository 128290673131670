import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthModule } from '@app/auth/auth.module';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@app/pipes/pipes.module';

import { RouterModule } from '@angular/router';
import { CartSummaryPage } from './cart-summary/cart-summary.page';
import { CheckoutSummaryPage } from './checkout-summary/checkout-summary.page';
import { CheckoutHeaderComponent } from './checkout-header/checkout-header.component';
import { CheckoutServiceOptionComponent } from './checkout-service-option/checkout-service-option.component';
import { CheckoutCartItemsComponent } from './checkout-cart-items/checkout-cart-items.component';
import { CheckoutCartTotalsComponent } from './checkout-cart-totals/checkout-cart-totals.component';
import { CheckoutOptionsComponent } from './checkout-options/checkout-options.component';

import { StripePayButtonComponent } from './components/stripe-pay-button/stripe-pay-button.component';

import { PaymentCardComponent } from './modals/payment-card/payment-card.component';
import { PaymentMethodsComponent } from './modals/payment-methods/payment-methods.component';
import { SelectGratuityComponent } from './modals/select-gratuity/select-gratuity.component';
import { CustomGratuityComponent } from './modals/custom-gratuity/custom-gratuity.component';
import { CheckoutUserDetailsComponent } from './checkout-user-details/checkout-user-details.component';
import { CheckoutPaymentDetailsComponent } from './checkout-payment-details/checkout-payment-details.component';
import { VoucherModalComponent } from './modals/voucher-modal/voucher-modal.component';
import { WalletListComponent } from './components/wallet-list/wallet-list.component';
import { PayloadComponent } from './modals/payment-card/payload/payload.component';
import { StripeComponent } from './modals/payment-card/stripe/stripe.component';
import { CheckoutGratuityComponent } from './checkout-gratuity/checkout-gratuity.component';

@NgModule({
  declarations: [
    CartSummaryPage,
    CheckoutSummaryPage,
    CheckoutHeaderComponent,
    CheckoutServiceOptionComponent,
    CheckoutCartItemsComponent,
    CheckoutCartTotalsComponent,
    CheckoutGratuityComponent,
    CheckoutOptionsComponent,
    PaymentCardComponent,
    PayloadComponent,
    StripeComponent,
    PaymentMethodsComponent,
    SelectGratuityComponent,
    CustomGratuityComponent,
    CheckoutUserDetailsComponent,
    CheckoutPaymentDetailsComponent,
    VoucherModalComponent,
    WalletListComponent,
    StripePayButtonComponent
  ],
  entryComponents: [
    CartSummaryPage,
    CheckoutSummaryPage,
    PaymentCardComponent,
    PaymentMethodsComponent,
    SelectGratuityComponent,
    CustomGratuityComponent,
    VoucherModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    IonicModule,
    AuthModule,
    PipesModule
  ],
  exports: [
    PaymentCardComponent
  ]
})
export class CheckoutModule {}
