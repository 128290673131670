export enum OrderChannel {
  APP = 'app',
  BANQUET = 'banquet',
  KIOSK = 'kiosk',
  POS = 'point-of-sale',
  QSR = 'qsr',
  SIT_DOWN = 'sit-down',
  WEB = 'web'
}

export enum PricingStrategy {
  INHERIT = 'inherit',
  OVERRIDE = 'override'
}
