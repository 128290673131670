export enum UserType {
  CUSTOMER = 'customer',
  ORG = 'org',
  PARTAKE = 'partake',
}

export enum UserState {
  UNVERIFIED = 'unverified',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  DELETED = 'deleted'
}
