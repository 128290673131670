import _ from 'lodash';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  AmplitudeTracker,
  FacebookEvent,
  GoogleEvent,
  PartakeEvent
} from '../../models';
import { ConfigService } from '../../config';

declare let dataLayer: Array<any>;
declare let fbq: (...args: any[]) => any;

interface IAnalyticsEvent {
  ga?: {
    event: string;
    url: string;
  };
  fb?: {
    type: string;
    event: string;
    params?: any;
  };
}

const validHosts = ['partakelive.com'];

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private hostname: string;
  private amplitudeClient: AmplitudeTracker;

  constructor(
    private config: ConfigService
  ) {
    this.hostname = window.location.hostname;
  }

  public initTrackingTools(user?: any) {
    this.amplitudeClient = new AmplitudeTracker(this.hostname, this.config?.whiteLabelName);
    if (user) {
      this.setUser(user);
    }
  }

  public setUser(user: any) {
    this.amplitudeClient.setUserId(user._id);
    this.amplitudeClient.setUserInfo(user);
  }

  public track(event: PartakeEvent) {
    this.amplitudeClient.trackEvent(event);
    if (environment.production) {
      const fb = new FacebookEvent(event);
      fb.track();
      const ga = new GoogleEvent(event);
      ga.track();
    }
  }

  /**
   * @deprecated
   */
  public trackEvent(event: IAnalyticsEvent) {
    if (environment.production) {
      if (event.ga) {
        if (window['dataLayer']) {
          dataLayer.push(event.ga);
        }
      }
      if (event.fb && _.includes(validHosts, this.hostname)) {
        if (window['fbq']) {
          fbq(event.fb.type, event.fb.event, event.fb.params);
        }
      }
    }
  }
}
