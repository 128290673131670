import { OrderedItem, OrderedItemModifier, OrderedItemTax, TaxCodeData } from '../interfaces';
import { ModifierGroup } from '../models';

export default class TaxCalculator {
  /**
   * @description
   * This calculates the tax amount for a price that includes tax give a tax rate
   */
  static calculateTaxIncludedTaxAmount(price: number, taxRate: number): number {
    return Math.ceil((price * taxRate) / (1 + taxRate));
  }

  /**
   * @description
   * Get the tax amount for a price that excludes tax given a tax rate
   */
  static calculateTax(amount: number, taxRate: number): number {
    return Math.ceil(amount * taxRate);
  }

  static calculatePreTaxPriceFromTaxes(amount: number, taxes: TaxCodeData[]): number {
    const taxRate = taxes.reduce((acc, tax) => acc + tax.taxRate, 0);
    return amount - TaxCalculator.calculateTaxIncludedTaxAmount(amount, taxRate * 0.01);
  }

  static getItemTaxes(
    orderedItem: OrderedItem,
    taxes: TaxCodeData[],
  ): OrderedItemTax[] {
    const { taxIncluded, unitPrice, quantity, itemDiscountTotal } = orderedItem;
    const afterDiscountPrice = (unitPrice * quantity) - itemDiscountTotal;
    if (!afterDiscountPrice) {
      return [];
    }

    const newPreTaxPrice = taxIncluded ? TaxCalculator.calculatePreTaxPriceFromTaxes(afterDiscountPrice, taxes) : afterDiscountPrice;

    return taxes.map((tax) => {
      return {
        id: tax._id,
        total: TaxCalculator.calculateTax(newPreTaxPrice, tax.taxRate * 0.01)
      };
    });
  }

  static getModifierItemTaxes(
    modifier: OrderedItemModifier,
    itemQuantity: number,
    taxes: TaxCodeData[],
  ): OrderedItemTax[] {
    const {  unitPrice, discountTotal, taxIncluded } = modifier;
    const afterDiscountPrice = (unitPrice * itemQuantity) - discountTotal;
    if (!afterDiscountPrice) {
      return [];
    }
    const newPreTaxPrice = taxIncluded ? TaxCalculator.calculatePreTaxPriceFromTaxes(afterDiscountPrice, taxes) : afterDiscountPrice;

    const modTaxes = taxes.map((tax) => {
      return {
        id: tax._id,
        total: TaxCalculator.calculateTax(newPreTaxPrice, tax.taxRate * 0.01)
      };
    });
    return modTaxes;
  }

  static getTaxTotal(taxes: OrderedItemTax[]): number {
    return taxes.reduce((acc, tax) => acc + tax.total, 0);
  }

  static setTaxesForOrderedItemModifiers(
    orderedItem: OrderedItem,
    taxesById: Record<string, TaxCodeData>,
    modifierGroupsById: Record<string, ModifierGroup>
  ): void {
    orderedItem.modifierTaxIncludedTaxTotal = 0;
    orderedItem.modifiers.forEach((modifier) => {
      const modifierGroup = modifierGroupsById[modifier.modifierSet];
      const modifierOption = modifierGroup?.data.options.find((option) => option._id === modifier.modifierSetOption);
      const modifierTaxes = modifierOption?.taxCodes.map((taxId) => taxesById[taxId]);
      modifier.taxes = TaxCalculator.getModifierItemTaxes(modifier, orderedItem.quantity, modifierTaxes);
      modifier.taxTotal = TaxCalculator.getTaxTotal(modifier.taxes);
      if (modifier.taxIncluded) {
        orderedItem.modifierTaxIncludedTaxTotal += modifier.taxTotal;
      }
    });
    orderedItem.modifierTaxTotal = orderedItem.modifiers.reduce((acc, modifier) => acc + modifier.taxTotal, 0);
  }

  static setTaxesForOrderedItem(orderedItem: OrderedItem, taxes: TaxCodeData[]): void {
    orderedItem.taxes = TaxCalculator.getItemTaxes(orderedItem, taxes);
    orderedItem.itemTaxTotal = TaxCalculator.getTaxTotal(orderedItem.taxes);
  }
}
