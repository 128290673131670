import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  private _chars = 36;

  transform(text: string, chars?: number): string {
    if (!text) {
      return '';
    }
    const end = (chars || this._chars) - 3;
    const ellipses = text.length > end ? '...' : '';
    return text.substring(0, end) + ellipses;
  }

}
