import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderInstructions'
})
export class OrderInstructionsPipe implements PipeTransform {

  constructor() { }

  transform(serviceOption: string, venueType: string, location?: any): string {
    let instructions = '';
    if (serviceOption) {
      switch (serviceOption) {
        case 'pickup':
          instructions = 'Please skip the line at the Partake Pay Express Lane and let the cashier know your order number.';
          break;
        case 'delivery':
          switch (venueType) {
            case 'golf-club':
              instructions = 'Please stay in your cart until your order is delivered, see you soon!';
              break;
            case 'arena':
              if (location && location.customDeliveryNotice) {
                instructions = location.customDeliveryNotice;
              } else {
                instructions = 'Please stay in your seat until your order is delivered, see you soon!';
              }
              break;
            default:
              instructions = 'Please stay in your location until your order is delivered, see you soon!';
              break;
          }
      }
    }
    return instructions;
  }

}
