import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';

import { BettingPipesModule } from './betting/betting-pipes.module';
import { SportsPipesModule } from './sports/sports-pipes.module';

import { ConcessionPipe } from './concession.pipe';
import { MenuItemPipe } from './menu-item.pipe';
import { PhonePipe } from './phone.pipe';
import { StatusPipe } from './status.pipe';
import { OrderTimePipe } from './order-time.pipe';
import { OrderInstructionsPipe } from './order-instructions.pipe';
import { CCBrandPipe } from './cc-brand.pipe';
import { ClassificationPipe } from './classification.pipe';
import { FirstNamePipe } from './first-name.pipe';
import { MaxEntriesPipe } from './max-entries.pipe';
import { EntryFeePipe } from './entry-fee.pipe';
import { ShortenPipe } from './shorten.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { PtTitleCasePipe } from './pt-title-case.pipe';
import { AddressPipe } from './address.pipe';
import { CamelCaseToSpacesPipe } from './camel-case-to-spaces.pipe';

@NgModule({
  declarations: [
    AddressPipe,
    CamelCaseToSpacesPipe,
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    ClassificationPipe,
    FirstNamePipe,
    MaxEntriesPipe,
    EntryFeePipe,
    ShortenPipe,
    SafeHtmlPipe,
    PtTitleCasePipe,
  ],
  exports: [
    BettingPipesModule,
    SportsPipesModule,
    AddressPipe,
    CamelCaseToSpacesPipe,
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    ClassificationPipe,
    FirstNamePipe,
    MaxEntriesPipe,
    EntryFeePipe,
    ShortenPipe,
    SafeHtmlPipe,
    PtTitleCasePipe
  ],
  imports: [
    CommonModule,
    SportsPipesModule,
    BettingPipesModule
  ],
  providers: [
    AddressPipe,
    TitleCasePipe,
    ConcessionPipe,
    MenuItemPipe,
    PhonePipe,
    StatusPipe,
    OrderTimePipe,
    OrderInstructionsPipe,
    CCBrandPipe,
    ClassificationPipe,
    FirstNamePipe,
    MaxEntriesPipe,
    EntryFeePipe,
    CurrencyPipe,
    ShortenPipe,
    SafeHtmlPipe,
    PtTitleCasePipe
  ]
})
export class PipesModule { }
