import { NgModule } from '@angular/core';

import { GameMultiplePipe } from './game-multiple.pipe';

@NgModule({
  declarations: [
    GameMultiplePipe
  ],
  exports: [
    GameMultiplePipe
  ],
  imports: [
  ],
  providers: [
    GameMultiplePipe
  ]
})
export class BettingPipesModule { }
