export enum EducationType {
  VIDEO = 'video',
  FAQ = 'faq',
  INTRO = 'intro',
  TERMS_CONDITIONS = 'terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy'
}

export enum IntroModule {
  BETTING = 'betting',
  FANTASY = 'fantasy',
  ORDERING = 'ordering',
  GROUPS = 'groups'
}

