import { Injectable } from '@angular/core';
import { GenericModalController } from '@app/shared/modals/modal/generic-modal.controller';
import { ICustomerEducation } from '../../../models';
import { EducationModule } from '../education.module';
import { GenericEducationModalComponent } from './generic-education-modal.component';

export interface GenericModalOptions {
  title: string;
  education?: ICustomerEducation;
}

@Injectable({
  providedIn: EducationModule
})
export class GenericEducationModalController {
  constructor(
    private modalCtrl: GenericModalController
  ) { }

  async show(options?: GenericModalOptions): Promise<any> {
    return this.modalCtrl.show({
      title: options.title,
      component: GenericEducationModalComponent,
      componentProps: { education: options.education },
      hideFooter: true
    });
  }

}
