import { AbstractModel } from '../abstract.model';
import { MenuItem } from './menu-item';

export interface MenuSectionData {
  name: string;
  displayName?: string;
  menuItemIds: string[];
  menuItems?: MenuItem[];
}

export class MenuSection extends AbstractModel<MenuSectionData> {
  constructor(public data: MenuSectionData) {
    super(data);
  }
}
