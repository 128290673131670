import _ from 'lodash';

export function addLinkToDom(type: string, url: string) {
  const link = document.createElement('link');
  document.head.appendChild(link);
  link.type = type;
  link.rel = 'stylesheet';
  link.href = url;
}

export function addCustomCodeToDom(customCode: Array<{ type: string; url: string }>) {
  _.each(customCode, (code: { type: string; url: string }) => {
    if (code.type === 'css') {
      addLinkToDom('text/css', code.url);
    }
  });
}
