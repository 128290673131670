import { PartakeEvent } from './partake';

export abstract class BaseEvent {
  protected convertedEvent: any;

  constructor(protected partakeEvent: PartakeEvent) {
    this.convertEvent();
  }

  protected abstract convertEvent(): void;

  protected abstract track(): void;

  getEventPayload() {
    return this.convertedEvent;
  }
}
