import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import 'jquery';
import pfjs from 'fingerprintjs2';
import { UserService } from '@app/providers/user/user.service';
import { AnalyticsService } from '@app/providers/analytics/analytics.service';
import { AnalyticsEventType } from './enums';
import { ConfigService } from './config';
import { AppearanceHelper } from './helpers';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private config: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
    private userService: UserService
  ) {
    this.init();
  }

  private async init() {
    if (navigator.userAgent.match('CriOS')) {
      const root = document.documentElement;
      root.style.setProperty('--ion-safe-area-top', '0px');
      root.style.setProperty('--ion-safe-area-bottom', '0px');
    }
    setTimeout(() => {
      pfjs.get(components => {
        const hash = pfjs.x64hash128(components.map(pair => pair.value).join(), 31);
        this.userService.setDeviceHash(hash);
      });
    }, 500);

    this.trackPageEvents();
    const user = await this.userService.getMe();
    this.analyticsService.initTrackingTools(user);
    AppearanceHelper.addCustomCodeToDom(this.config.customCode);
  }

  private trackPageEvents() {
    this.route.queryParams.subscribe((params: any) => {
      if (params?.referral) {
        this.userService.setReferral(params.referral);
      }
    });
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.analyticsService.track({
          type: AnalyticsEventType.PAGE_VIEW,
          url: event.url
        });
      }
    });
  }

}
