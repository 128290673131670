<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="title">{{title}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen="true">
  <ng-template #content></ng-template>
</ion-content>
<!-- OLD BUTTONS -->
<ion-footer *ngIf="!hideFooter">
  <ion-toolbar *ngIf="!footerButtons">
    <ion-buttons slot="start">
      <ion-button [size]="buttons?.skip.size || 'small'" fill="clear" (click)="action(true)">
        {{ buttons?.skip.title || 'SKIP' }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button [size]="buttons?.next.size || 'small'" fill="solid" (click)="action(false)">
        {{ buttons?.next.title || 'NEXT' }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <!-- NEW BUTTONS -->
  <ion-toolbar *ngIf="footerButtons">
    <ion-buttons slot="start" *ngIf="footerButtons.start?.length">
      <ion-button
        *ngFor="let btn of footerButtons.start"
        (click)="takeAction(btn)"
        [size]="btn.size || 'small'"
        [fill]="btn.fill || 'clear'"
        [color]="btn.color || 'primary'">
        {{ btn.label }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" *ngIf="footerButtons.end?.length">
      <ion-button
        *ngFor="let btn of footerButtons.end"
        (click)="takeAction(btn)"
        [size]="btn.size || 'small'"
        [fill]="btn.fill || 'clear'"
        [color]="btn.color || 'primary'">
        {{ btn.label }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>