<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        appEventTracker
        [event]="{ value: 'Skip - Education Step ' + step }"
        (click)="skip()">
        SKIP
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content fullheight class="ion-padding education-content">
  <!-- IMAGE SECTION -->
  <ion-row *ngIf="imageUrl">
    <ion-img class="image" [src]="imageUrl"></ion-img>
  </ion-row>
  <!-- CONTENT -->
  <ion-row class="section" *ngFor="let section of sections">
    <ion-col class="ion-no-padding">
      <div class="title" *ngIf="section.title">{{section.title}}</div>
      <div class="content" *ngIf="section.content">{{section.content}}</div>
    </ion-col>
  </ion-row>
</ion-content>
<ion-footer>
  <ion-row class="ion-padding">
    <ion-col class="ion-no-padding" *ngIf="step > 0 && steps?.length > 1">
      <ion-button
        appEventTracker
        [event]="{ value: 'Prev - Education Step ' + step }"
        expand="block"
        color="medium"
        (click)="prev()">
        PREV
      </ion-button>
    </ion-col>
    <ion-col class="ion-no-padding">
      <ion-button
        appEventTracker
        [event]="{ value: 'Next - Education Step ' + step }"
        expand="block"
        color="secondary"
        (click)="next()">
        NEXT
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
