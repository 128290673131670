import _ from 'lodash';
import {
  OrderStatus
} from '../../enums';
import {
  OrderData,
  OrderedItem,
} from '../../interfaces';
import { AbstractModel } from '../abstract.model';
import { MenuItem } from '../location';

export class Order extends AbstractModel<OrderData> {
  static readonly keysToClean = ['item', 'restaurant', 'shift', 'employee', 'modifiers', 'taxes', 'id'];

  static readonly openOrderStatuses: OrderStatus[] = [
    OrderStatus.PENDING,
    OrderStatus.ACCEPTED,
    OrderStatus.READY_FOR_PICKUP,
    OrderStatus.OUT_FOR_DELIVERY,
    OrderStatus.POINT_OF_SALE
  ];

  private orderedItemsById: Record<string, any>;

  private transactions: any[];

  public itemsToFulfillCount = 0;

  constructor(public data: OrderData) {
    super(data);
    if (_.size(this.data?.items)) {
      this.orderedItemsById = _.keyBy(this.data.items.map((item) => {
        return new MenuItem(item.item as any);
      }), 'id');
      this.setItemNames();
      this.data.items = Order.stripDownToIds(this.data.items);
    }
    this.transactions = data.transactions || [];
  }

  get itemCount() {
    return _.size(this.data.items);
  }

  get taxesAndFees() {
    const taxes = this.get('taxTotal') || 0;
    const fees = this.get('serviceFee') || 0;
    const roundUp = this.get('roundUpTotal') || 0;
    return taxes + fees + roundUp;
  }

  get due() {
    return this.get('due');
  }

  get total() {
    return this.getTotal();
  }

  private setItemNames() {
    this.data.items.forEach((item: OrderedItem) => {
      if (!item.name) {
        item.name = _.get(item, 'item.name');
      }
    });
  }

  public static stripDownToIds<T>(items: Array<any>): Array<T> {
    return items.map((item: any) => {
      const copy = _.cloneDeep(item);
      _.each(Order.keysToClean, (key: string) => {
        const propertyValue = _.get(copy, key);
        if (_.isObject(propertyValue)) {
          copy[key] = _.get(propertyValue, '_id');
        }
        if (_.isArray(propertyValue)) {
          copy[key] = Order.stripDownToIds(propertyValue);
        }
      });
      return {
        ...copy
      } as T;
    });
  }

  public getOrderedItemsById() {
    return this.orderedItemsById;
  }

  private setSubTotal() {
    const subTotal = this.data.items.reduce((total, item) => {
      const modifierPrice = item.modifiers.reduce((modTotal, modifier) => {
        return modTotal + (modifier.unitPrice * modifier.quantity * item.quantity);
      }, 0);
      return total + item.unitPrice * item.quantity + modifierPrice;
    }, 0);
    this.data.subTotal = subTotal;
  }

  private setDiscountTotal() {
    const discountTotal = this.data.items.reduce((total, item) => {
      if (item.discountTotal) {
        total += item.discountTotal;
      }
      return total;
    }, 0);
    this.data.discount = discountTotal;
  }

  private getTaxIncludedTaxTotal() {
    return this.data.items.reduce((total, item) => {
      const itemTaxIncludedTotal = item.taxIncluded ? item.itemTaxTotal : 0;
      const modifierTaxIncludedTotal = item.modifiers.reduce((modTotal, modifier) => {
        return modTotal + (modifier.taxIncluded ? modifier.taxTotal : 0);
      }, 0);
      return total + itemTaxIncludedTotal + modifierTaxIncludedTotal;
    }, 0);
  }

  private setTaxTotal() {
    const taxTotal = this.data.items.reduce((total, item) => {
      const itemTaxTotal = item.taxIncluded ? 0 : item.itemTaxTotal;
      const modifierTaxTotal = item.modifiers.reduce((modTotal, modifier) => {
        return modTotal + (modifier.taxIncluded ? 0 : modifier.taxTotal);
      }, 0);
      return total + itemTaxTotal + modifierTaxTotal;
    }, 0);
    this.data.taxTotal = taxTotal;
    this.data.taxIncludedTaxTotal = this.getTaxIncludedTaxTotal();
    this.data.venueTax = taxTotal; // TODO - REMOVE
  }

  private setServiceFee() {
    if (!this.data.items.length) {
      this.data.serviceFee = 0;
      this.data.partakeServiceFee = 0;
      return;
    }
    const serviceChargeTotal = this.data?.serviceCharges.reduce((total, serviceCharge) => {
      return total + serviceCharge.amount;
    }, 0);
    this.data.serviceFee = serviceChargeTotal;
    const partakeServiceChargeTotal = this.data?.serviceCharges.reduce((total, serviceCharge) => {
      if (serviceCharge.isPartake) {
        return total + serviceCharge.amount;
      }
      return total;
    }, 0);
    this.data.partakeServiceFee = partakeServiceChargeTotal;
  }

  private setRoundUpTotal() {
    if (!this.data.shouldRoundUp) {
      this.data.roundUpTotal = 0;
      return;
    }
    const preRoundTotal = this.data.subTotal +
      this.data.serviceFee +
      this.data.taxTotal +
      this.data.gratuity -
      this.data.discount;
    const roundedUpTotal = Math.ceil(preRoundTotal * 0.01) * 100;
    this.data.roundUpTotal = roundedUpTotal - preRoundTotal;
  }

  private getTotal() {
    return this.data.subTotal +
      this.data.serviceFee +
      this.data.taxTotal +
      this.data.roundUpTotal +
      this.data.gratuity -
      this.data.discount;
  }

  private setTotal() {
    const total = this.getTotal();
    this.data.total = total;
  }

  private setAmountPaid() {
    if (_.size(this.transactions)) {
      const amountPaid = this.transactions.reduce((paidTotal: number, transaction: any) => {
        if (transaction.state === 'captured') {
          if (transaction.type === 'sale') {
            return paidTotal + transaction.amount;
          }
          if (transaction.type === 'refund') {
            return paidTotal - transaction.amount;
          }
        }
        return paidTotal;
      }, 0);
      this.data.amountPaid = amountPaid;
    } else {
      this.data.amountPaid = 0;
    }
  }

  private setDue() {
    this.data.due = this.data.total - this.data.amountPaid;
  }

  public addToOrder(item: OrderedItem): void {
    this.data.items.push(item);
    if (item.isAlcoholic) {
      this.set('alcoholCount', this.get('alcoholCount') + item.quantity);
    }
    this.updateCosts();
  }

  public removeFromOrder(idx: number): OrderedItem {
    const [removedOrderedItem]: Array< OrderedItem> = this.data.items.splice(idx, 1);
    if (removedOrderedItem.isAlcoholic) {
      this.set('alcoholCount', this.get('alcoholCount') - removedOrderedItem.quantity);
    }
    if (!removedOrderedItem.isSaved) {
      this.data.removed.push({
        ...removedOrderedItem,
        removedAt: new Date()
      });
    }
    this.updateCosts();
    return removedOrderedItem;
  }

  public setItemsToSaved() {
    this.data.items.forEach((item: OrderedItem) => {
      item.isSaved = true;
    });
  }

  public updateCosts(): void {
    this.setSubTotal();
    this.setDiscountTotal();
    this.setTaxTotal();
    this.setServiceFee();
    this.setRoundUpTotal();
    this.setTotal();
    this.setAmountPaid();
    this.setDue();
  }

  get json() {
    return {
      ...this.data,
      deliveryOption: _.toLower(this.get('orderDestination.behaviour'))
    };
  }
}
