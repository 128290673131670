import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/providers/auth/auth.service';
import { UserService } from '@app/providers/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthErrorInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private _router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const authService = this.injector.get(AuthService);
        const userService = this.injector.get(UserService);
        if (error.status === 403 || error.status === 401) {
          authService.setToken();
          userService.setMe();
          this._router.navigate(['/']);
        }
        return throwError(error);
      })
    );
  }
}
