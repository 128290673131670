import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxEntries'
})
export class MaxEntriesPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 0) {
      return value.toString();
    }
    return 'Unlimited';
  }
}
