import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'entryFee'
})
export class EntryFeePipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe
  ) { }

  transform(value: number, type = 'short'): string {
    if (value > 0) {
      return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
    }
    if (type === 'short') {
      return 'FREE';
    }
    return 'FREE TO PLAY';
  }
}
