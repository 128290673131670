import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(
    private _sanitizer: DomSanitizer,
  ) {}

  transform(value: string): SafeHtml {
    if (value) {
      return this._sanitizer.bypassSecurityTrustHtml(value);
    }
    return '';
  }

}
