import { environment } from '@env/environment';
import { ApiResources } from './api-resources';

const v1 = environment.urls.versions.v1;
const v2 = environment.urls.versions.v2;

export const Api = {
  ARENA: `${v2}/${ApiResources.ARENAS}`,
  PRICING_CONFIG: `${v1}/${ApiResources.PRICING_CONFIGS}`,
  COURSE: `${v2}/${ApiResources.COURSES}`,
  ATTRACTIONS: `${v2}/${ApiResources.ATTRACTIONS}`,
  CLASSIFICATIONS: `${v2}/${ApiResources.CLASSIFICATIONS}`,
  EVENTS: `${v2}/${ApiResources.EVENTS}`,
  ORDER: `${v1}/${ApiResources.ORDERS}`,
  MENU_ITEM: `${v2}/${ApiResources.MENU_ITEMS}`,
  RESTAURANT: `${v2}/${ApiResources.RESTAURANTS}`,
  TENDER_MAP: `${v1}/${ApiResources.TENDER_MAPS}`,
  CHARGE: `${v1}/${ApiResources.STRIPE}/order-charge`,
  WALLET: `${v1}/${ApiResources.STRIPE}/customer`,
  VENUE: `${v2}/${ApiResources.VENUES}`,
  FACEBOOK_SIGNIN: `${v1}/facebook_signin`,
  FACEBOOK_SIGNUP: `${v1}/facebook_signup`,
  IMAGES: `${v2}/${ApiResources.IMAGES}`,
  CHECK_IN: `${v2}/${ApiResources.POST}`,
  FAVORITE: `${v2}/${ApiResources.FAVORITES}`,
};
