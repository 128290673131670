import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ApiDomain, ApiResources, ApiVersion } from '@app/enums';
import { AppInjector } from '../app-injector.service';
import { ConfigService } from '../config/config.service';
import CacheClient from './cache-client';
import { ApiQuery } from './api-client.types';
import { ApiQueryParams } from './api-query-params';

export default class ApiClient {
  private env: string;
  private _base: string;
  private _domain: string;
  private _version: string;
  private _resource: string;
  private _ttl = 15;

  private _cachClient: CacheClient;
  private cacheCount = 1;

  constructor(
    private _http?: HttpClient
  ) {
    if (!_http) {
      this._http = AppInjector.getInjector().get(HttpClient);
    }
    const config = AppInjector.getInjector().get(ConfigService);
    this.env = config?.ENV || 'demo';
    this._cachClient = CacheClient.getInstance();
    return this;
  }

  private createUrl(parts: Array<string> = []) {
    return parts.filter((x) => x).join('/');
  }

  public setDomain(domain: ApiDomain, version: ApiVersion, resource?: ApiResources) {
    this._base = environment.urls.domains[domain][this.env];
    this._version = environment.urls.versions[version];
    this._resource = resource || null;
    this._domain = this.createUrl([this._base, this._version, this._resource]);
    return this;
  }

  public setVersion(version: ApiVersion) {
    this._version = version;
    this._domain = this.createUrl([this._base, this._version, this._resource]);
    return this;
  }

  public setResource(resource: ApiResources) {
    this._resource = resource;
    this._domain = this.createUrl([this._base, this._version, this._resource]);
    return this;
  }

  public setCacheTtl(ttl: number) {
    this._ttl = ttl;
    return this;
  }

  public self<Response>(query: ApiQuery, subCollectionResource?: ApiResources | string): Observable<Response> {
    if (!this._domain) {
      throw new Error('Invalid domain set');
    }
    const url =  this.createUrl([this._domain, subCollectionResource, 'self']);
    const params = new ApiQueryParams(query);
    const cacheUrl = `${url}?${params.toString()}`;
    const cached = this._cachClient.getRequest<Response>(cacheUrl);

    if (!cached) {
      const toCache$ = this._http
        .get<Response>(url, { params })
        .pipe(shareReplay(this.cacheCount));
      this._cachClient.cacheRequest({
        url,
        request: toCache$,
        ttl: this._ttl,
        cached: Date.now()
      });
      return toCache$;
    }
    return cached;
  }

  public findById<Response>(id: string, subCollectionResource?: ApiResources | string): Observable<Response> {
    if (!this._domain) {
      throw new Error('Invalid domain set');
    }
    const url =  this.createUrl([this._domain, subCollectionResource, id]);
    const cached = this._cachClient.getRequest<Response>(url);
    if (!cached) {
      const toCache$ = this._http.get<Response>(url).pipe(shareReplay(this.cacheCount));
      this._cachClient.cacheRequest({
        url,
        request: toCache$,
        ttl: this._ttl,
        cached: Date.now()
      });
      return toCache$;
    }
    return cached;
  }

  /**
   * @todo
   * - make query into query string vs just stringify
   * @param subCollectionResource
   * @param query
   */
  public get<Response>(query: ApiQuery, subCollectionResource?: ApiResources | string): Observable<Response> {
    if (!this._domain) {
      throw new Error('Invalid domain set');
    }
    const url =  this.createUrl([this._domain, subCollectionResource]);
    const params = new ApiQueryParams(query);
    const cacheUrl = `${url}?${params.toString()}`;
    const cached = this._cachClient.getRequest<Response>(cacheUrl);

    if (!cached) {
      const toCache$ = this._http
        .get<Response>(url, { params })
        .pipe(shareReplay(this.cacheCount));
      this._cachClient.cacheRequest({
        url: cacheUrl,
        request: toCache$,
        ttl: this._ttl,
        cached: Date.now()
      });
      return toCache$;
    }
    return cached;
  }

  public create<Response, Payload>(body: Payload, subCollectionResource?: ApiResources | string): Observable<Response> {
    if (!this._domain) {
      throw new Error('Invalid domain set');
    }
    this._cachClient.invalidateCache();
    const url =  this.createUrl([this._domain, subCollectionResource]);
    return this._http.post<Response>(url, body);
  }

  public post<Response, Payload>(body: Payload, subCollectionResource?: ApiResources | string): Observable<Response> {
    return this.create<Response, Payload>(body, subCollectionResource);
  }

  public update<Response, Payload>(id: string, body: Payload, subCollectionResource?: ApiResources | string): Observable<Response> {
    if (!this._domain) {
      throw new Error('Invalid domain set');
    }
    this._cachClient.invalidateCache();
    const url =  this.createUrl([this._domain, subCollectionResource, id]);
    return this._http.put<Response>(url, body);
  }

  public put<Response, Payload>(id: string, body: Payload, subCollectionResource?: ApiResources | string): Observable<Response> {
    return this.update<Response, Payload>(id, body, subCollectionResource);
  }

  public delete<Response>(id: string, subCollectionResource?: ApiResources | string): Observable<Response> {
    if (!this._domain) {
      throw new Error('Invalid domain set');
    }
    this._cachClient.invalidateCache();
    const url =  this.createUrl([this._domain, subCollectionResource, id]);
    return this._http.delete<Response>(url);
  }

  // File Upload
  public uploadFile(file: Blob, name: string, options?: { bucket: string }) {
    const formData = new FormData();
    formData.append('file', file, name);
    if (options?.bucket) {
      formData.append('bucket', options.bucket);
    }
    return this.post<any, any>(formData);
  }
}
