import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'ptTitleCase'
})
export class PtTitleCasePipe implements PipeTransform {
  constructor(
    private _titleCasePipe: TitleCasePipe
  ) {}

  transform(value: any): any {
    if (value) {
      const split = value.split('-');
      return this._titleCasePipe.transform(split.join(' '));
    }
    return value;
  }
}
