export enum AnalyticsEventType {
  PAGE_VIEW = 'page-view',
  LINK_CLICK = 'link-click',
  SIGNUP = 'signup',
  LOGIN = 'login',
  BUTTON_CLICK = 'button-click',
  ADD_TO_CART = 'add-to-cart',
  ENTER_CONTEST = 'enter-contest',
  DEPOSIT_FUNDS = 'deposit-funds',
}
