import amplitude, { AmplitudeClient } from 'amplitude-js';
import { APPLITUDE_KEYS } from '@env/environment';
import * as pkg from '../../../../package.json';
import { PartakeEvent } from './partake';


const env = window['env'] || 'demo';

export class AmplitudeTracker {
  private amplitudeClient: AmplitudeClient;

  constructor(domain: string, appId?: string) {
    if (APPLITUDE_KEYS[env]) {
      this.amplitudeClient = amplitude.getInstance();
      this.amplitudeClient.init(APPLITUDE_KEYS[env]);
      this.amplitudeClient.setDomain(domain);
      this.amplitudeClient.setVersionName(`v${pkg.version}`);
      if (appId) {
        this.amplitudeClient.setGroup('appId', appId);
      }
    }
  }

  public setUserId(id: string) {
    if (this.amplitudeClient) {
      this.amplitudeClient.setUserId(id);
    }
  }

  public setUserInfo(user: any) {
    if (this.amplitudeClient) {
      const { email, name, gender } = user;
      this.amplitudeClient.setUserProperties({ email, name, gender });
    }
  }

  public trackEvent(event: PartakeEvent) {
    if (this.amplitudeClient) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { user, type, ...ev } = event;
      this.amplitudeClient.logEvent(event.type, ev);
    }
  }
}
