export enum SoccerPeriod {
  FIRST_HALF = 1,
  SECOND_HALF = 2,
  EXTRA_TIME_FIRST_HALF = 3,
  EXTRA_TIME_SECOND_HALF = 4,
  PENALTY_SHOOTOUT = 5,
  HALF_TIME = 10,
  END_OF_SECOND_HALF = 11,
  EXTRA_TIME_HALF_TIME = 12,
  END_OF_EXTRA_TIME = 13,
  FULL_TIME = 14,
  PRE_MATCH = 16,
}
