import { ValidatorFunc } from './schema';

export function minLength(len: number): ValidatorFunc {
  return (value: any): boolean => {
    if (!value) {
      return false;
    }
    return value.length >= len;
  };
}

export function maxLength(len: number): ValidatorFunc {
  return (value: any): boolean => {
    if (!value) {
      return true;
    }
    return value.length <= len;
  };
}

export function pattern(regExp: RegExp): ValidatorFunc {
  return (value: any): boolean => {
    return regExp.test(value);
  };
}
