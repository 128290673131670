import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FooterButtons, ModalComponent } from './modal.component';

// eslint-disable-next-line @typescript-eslint/ban-types
type ComponentRef = Function | HTMLElement | string | null;

interface ModalOptions<T extends ComponentRef = ComponentRef> {
  title: string;
  component: T;
  componentProps?: any;
  cssClass?: string;
  hideFooter?: boolean;
  buttons?: { skip: { title: string; size: string }; next: { title: string; size: string } };
  footerButtons?: FooterButtons;
  next?: (...args: any[]) => any;
  skip?: (...args: any[]) => any;
}

@Injectable({
    providedIn: 'root'
})
export class GenericModalController {

  constructor(
    private modalCtrl: ModalController
  ) { }

  async show(options: ModalOptions): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const modal = await this.modalCtrl.create({
          cssClass: `pt-modal generic ${options.cssClass || ''}`,
          component: ModalComponent,
          componentProps: options
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
