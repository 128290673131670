import { Injectable, Injector } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from } from 'rxjs';
import * as pkg from '../../../package.json';
import { ApiHeader, MenuFeature, OrderChannel } from '../enums';
import { LocalStorage } from '../helpers';
import { AuthService } from '../providers/auth/auth.service';
import { ConfigService } from '../config';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private orgId: string;

  private venueId: string;

  private locationId: string;

  constructor(
    private injector: Injector,
    private config: ConfigService
  ) {}

  private async loadIds() {
    this.orgId = await LocalStorage.getItem(ApiHeader.ORG_ID);
    this.venueId = await LocalStorage.getItem(ApiHeader.VENUE_ID);
    this.locationId = await LocalStorage.getItem(ApiHeader.LOCATION_ID);
  }

  private async handle(req: HttpRequest<any>, next: HttpHandler) {
    await this.loadIds();
    const authService = this.injector.get(AuthService);
    const authToken = await authService.getToken();

    let headers = req.headers
      .set(ApiHeader.API_VERSION, '2')
      .set(ApiHeader.APP_VERSION, pkg.version)
      .set(ApiHeader.ORDER_CHANNEL, OrderChannel.WEB)
      .set(ApiHeader.MENU_FEATURE, `${MenuFeature.TAX_INCLUDED}`)
      .set(ApiHeader.APP_SCOPE, this.config.customerAccountScope);

    if (this.config.whiteLabelId) {
      headers = headers.set(ApiHeader.APP_NAME, this.config.whiteLabelName);
      headers = headers.set(ApiHeader.APP_ID, this.config.whiteLabelId);
    }
    if (authToken) {
      headers = headers.set(ApiHeader.AUTH_TOKEN, authToken);
    }
    if (this.orgId) {
      headers = headers.set(ApiHeader.ORG_ID, this.orgId);
    }
    if (this.venueId) {
      headers = headers.set(ApiHeader.VENUE_ID, this.venueId);
    }
    if (this.locationId) {
      headers = headers.set(ApiHeader.LOCATION_ID, this.locationId);
    }

    const authRequest = req.clone({ headers });
    return next.handle(authRequest).toPromise();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return from(this.handle(req, next));
  }
}
