import { OrderChannel } from '../../enums';
import { AbstractModel } from '../abstract.model';
import { MenuSection } from './menu-section';

export interface MenuData {
  name: string;
  channel: OrderChannel;
  menuSectionIds: string[];
  menuSections?: MenuSection[];
}

export class Menu extends AbstractModel<MenuData> {
  constructor(public data: MenuData) {
    super(data);
  }
}
