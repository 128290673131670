import _ from 'lodash';
import { AnalyticsEventType } from '../../enums';
import { BaseEvent } from './base-event';
import { PartakeEvent } from './partake';

export interface IFacebookEventParams {
  value: number;
  currency: string;
  content_name: string;
}

export interface IFacebookEvent {
  type: string;
  event: string;
  params?: IFacebookEventParams;
}

declare let fbq: (...args: any[]) => any;

export class FacebookEvent extends BaseEvent {
  private validHosts = ['partakelive.com'];
  private hostname: string;

  constructor(event: PartakeEvent) {
    super(event);
    this.hostname = window.location.hostname;
  }

  private getEvent(): string {
    switch (this.partakeEvent?.type) {
      case AnalyticsEventType.SIGNUP:
        return 'CompleteRegistration';
      case AnalyticsEventType.PAGE_VIEW:
        return 'PageView';
      case AnalyticsEventType.ENTER_CONTEST:
        return 'SubmitApplication';
    }
    return null;
  }

  private getParams(): IFacebookEventParams {
    if (_.includes([AnalyticsEventType.SIGNUP, AnalyticsEventType.PAGE_VIEW], this.partakeEvent.type)) {
      return null;
    }
    return {
      value: +this.partakeEvent.value ?? 0.00,
      currency: 'USD',
      content_name: this.partakeEvent.type
    };
  }

  convertEvent() {
    const params = this.getParams();
    const event = this.getEvent();
    console.log('FACEBOOK EVENT', event);
    if (event) {
      this.convertedEvent = {
        type: 'track',
        event,
        ...(params && { params })
      };
    }
  }

  track(): void {
    if (window['fbq'] && this.convertedEvent && _.includes(this.validHosts, this.hostname)) {
      fbq(this.convertedEvent.type, this.convertedEvent.event, this.convertedEvent.params);
    }
  }
}
