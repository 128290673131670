import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(status: string): string {
    let newStatus = '';
    switch (status) {
      case 'pending':
        newStatus = 'Received';
        break;
      case 'accepted':
        newStatus = 'Preparing';
        break;
      case 'point-of-sale':
        newStatus = 'Open Tab';
        break;
      case 'out-for-delivery':
        newStatus = 'Out for Delivery';
        break;
      case 'ready-for-pickup':
        newStatus = 'Ready for Pickup';
        break;
      case 'delivered':
        newStatus = 'Delivered';
        break;
      case 'cancelled':
        newStatus = 'Cancelled';
        break;
    }
    return newStatus;
  }

}
