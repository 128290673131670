export enum CustomPageStyle {
  BRANDED = 'branded',
  DEFAULT = 'default'
}

export enum AppModule {
  ACCOUNT = 'account',
  DISCOVER = 'discover',
  FAVORITES = 'favorites',
  GAMES = 'fantasy',
  LIVE = 'live',
  MENU = 'menu',
  ORDERING = 'orders',
  SEARCH = 'search',
  VENUES = 'venues'
}

export enum CustomStringKey {
  SIGNUP_TITLE = 'signup-title',
  SIGNUP_DESCRIPTION = 'signup-description',
  SIGNUP_BUTTON = 'signup-button',
  LOGIN_TITLE = 'login-title',
  LOGIN_DESCRIPTION = 'login-description',
  LOGIN_BUTTON = 'login-button',
  ADD_PHONE_TITLE = 'add-phone-title',
  ADD_PHONE_DESCRIPTION = 'add-phone-description',
  ADD_PHONE_BUTTON = 'add-phone-button',
  ACCOUNT_LANDING_TITLE = 'account-landing-title',
  ACCOUNT_LANDING_DESCRIPTION = 'account-landing-description',
}

export interface AppCustomPage {
  name: string;
  settings: {
    page: CustomPageStyle;
    title?: string;
  };
}

export interface AppCustomCode {
  type: string;
  url: string;
}

export interface AppNavigationTabBase {
  title: string;
  iconName: string;
};

export interface AppNavigationByAppUrl {
  url: AppModule;
  id?: string;
  venueId?: string;
}

export interface AppNavigationByExternalUrl {
  externalUrl: string;
}

export type AppNavigationTab = AppNavigationTabBase &
  (AppNavigationByAppUrl | AppNavigationByExternalUrl);

export type AppNavigationAccountSection = AppNavigationTabBase & {
  url: string;
};

export interface AppPageTitle {
  page: string;
  value: string;
}

export interface AppAuthPage {
  placeholderText?: string;
  loginText?: string;
  signupText?: string;
  requireUsername?: boolean;
}

export interface AppBrand {
  name: string;
  logo: {
    url: string;
  };
}

export interface AppConfig {
  customerAccountScope: string;
  brand: AppBrand;
  home: string;
  auth: AppAuthPage;
  customCode: AppCustomCode[];
  customPages: AppCustomPage[];
  tabs: AppNavigationTab[];
  classifications: string[];
  titles: AppPageTitle[];
  accountMenu: AppNavigationAccountSection[];
}

export interface CustomString {
  key: CustomStringKey;
  value: string;
}

export class ConfigService implements AppConfig {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public ENV = 'demo';

  public customerAccountScope = 'partake';

  public whiteLabelId: string;

  public whiteLabelName: string;

  public brand: AppBrand;

  public auth: AppAuthPage = {
    requireUsername: true
  };

  public customStrings: CustomString[] = [];

  public customCode: AppCustomCode[] = [];

  public customPages: AppCustomPage[] = [
    {
      name: 'fantasy',
      settings: {
        page: CustomPageStyle.BRANDED
      }
    },
    {
      name: 'live',
      settings: {
        page: CustomPageStyle.BRANDED
      }
    }
  ];

  public tabs: AppNavigationTab[] = [
    {
      title: 'Home',
      iconName: 'home-outline',
      url: AppModule.DISCOVER
    },
    {
      title: 'Search',
      iconName: 'search-outline',
      url: AppModule.SEARCH
    },
    {
      title: 'Live',
      iconName: 'play-circle-outline',
      url: AppModule.LIVE
    },
    {
      title: 'Favorites',
      iconName: 'heart-outline',
      url: AppModule.FAVORITES
    },
    {
      title: 'My Account',
      iconName: 'person-outline',
      url: AppModule.ACCOUNT
    }
  ];

  public classifications: string[] = [];

  public titles: AppPageTitle[] = [];
  public home = '/discover';

  public accountMenu = [
    {
      url: '/app/account/profile',
      iconName: 'person-outline',
      title: 'My Profile'
    },
    {
      url: '/app/account/payment',
      iconName: 'card-outline',
      title: 'Payments'
    },
    {
      url: '/app/account/balances',
      iconName: 'cash-outline',
      title: 'Account Balances'
    },
    {
      url: '/app/account/orders',
      iconName: 'time-outline',
      title: 'Order History'
    },
    {
      url: '/app/account/fantasy/history',
      iconName: 'game-controller-outline',
      title: 'Game History'
    },
    {
      url: '/app/account/settings',
      iconName: 'settings-outline',
      title: 'Settings'
    }
  ];

  public getTabForAppUrl(url: string): AppNavigationTab {
    return this.tabs.find((t) => {
      return (t as AppNavigationByAppUrl).url === url;
    });
  }

  public getCustomString(key: CustomStringKey): string | null {
    const customStringForKey =  this.customStrings.find((customString) => {
      return customString.key === key;
    });
    return customStringForKey?.value;
  }
}
