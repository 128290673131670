import { DiningOptionUsageType, PricingStrategy } from '../../enums';
import * as StringHelper from '../../helpers/string.helper';
import { IImageFile } from '../../interfaces';
import { AbstractModel } from '../abstract.model';
import { DiningOptionData } from './dining-option';
import { LocationData } from './location';
import { MenuItemStationData } from './menu-item-station';
import { PriceOptionWithPromo } from './price-option';
import { ProductData } from './product';

export type WithInventoryItem<T> = T & { inventoryItem: any };

export interface MenuItemCategory {
  _id: string;
  name: string;
  type: string;
}

export interface MenuItemData {
  restaurant: LocationData['_id'] | LocationData;
  product: ProductData['_id'] | ProductData;
  name: string;
  posName: string;
  description: string;
  category: MenuItemCategory;
  isOpen: boolean;
  pricingStrategy: PricingStrategy;
  priceOptions: PriceOptionWithPromo[];
  diningOptionUsageType: DiningOptionUsageType;
  diningOptions: DiningOptionData['_id'][] | DiningOptionData[];
  stations: MenuItemStationData['_id'][] | MenuItemStationData[];
  discounts: string[] | any[];
  isPublic: boolean;
  images: IImageFile[];
  availableQuantity: number;

  // Stuff that comes from the product
  isAlcoholic?: boolean;
  taxCodes: any[];

  /**
   * @deprecated
   */
  price: number;
  /**
   * @deprecated
   */
  imageUrl: string;
}

export type MenuItemDataWithImages = MenuItemData & {
  imageUrl?: string;
  fullImageUrl?: string;
};

export class MenuItem extends AbstractModel<MenuItemData> {
  constructor(public data: MenuItemData) {
    super(data);
  }

  public displayPrice(): string {
    if (!this.data.priceOptions?.length) {
      return `${(this.data.price * 0.01).toLocaleString('en-us')}`;
    }
    const prices = this.data.priceOptions.map((priceOption) => {
      return priceOption.price;
    });
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    if (min === max) {
      return `${StringHelper.currencyFromPrice(min)}`;
    }
    return `${StringHelper.currencyFromPrice(min)}-${StringHelper.currencyFromPrice(max)}`;
  }

  public promoNames() {
    const promoNames = this.data.priceOptions.filter((priceOption) => {
      return priceOption.preDiscountPrice && priceOption.tag;
    }).map((priceOption) => {
      return priceOption.tag;
    });
    return promoNames.join(', ');
  }
}
