import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsEventType } from '../enums';
import { PartakeEvent } from '../models';
import { AnalyticsService } from '../providers/analytics/analytics.service';

@Directive({
  selector: '[appEventTracker]'
})
export class EventTrackerDirective {
  @Input() event: any;

  @HostListener('click') onClick() {
    const { type, ...data } = this.event;
    const ev: PartakeEvent = {
      type: type ?? AnalyticsEventType.LINK_CLICK,
      ...data
    };
    this.analytics.track(ev);
  }

  constructor(
    private analytics: AnalyticsService
  ) { }

}
