import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { EducationModalComponent } from './education-modal/education-modal.component';
import { GenericEducationModalComponent } from './generic-education-modal/generic-education-modal.component';

@NgModule({
  declarations: [
    EducationModalComponent,
    GenericEducationModalComponent
  ],
  entryComponents: [
    EducationModalComponent,
    GenericEducationModalComponent
  ],
  exports: [
    EducationModalComponent,
    GenericEducationModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    DirectivesModule
  ]
})
export class EducationModule { }
