import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';
import { ConfigService } from '../../config/config.service';
import { LocalStorage } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _HASH_KEY = 'device-hash';
  private _USER_KEY = 'user';

  private _userListener = new Subject<any>();
  user = this._userListener.asObservable();

  private path = 'users';
  private domain: string;
  private baseUrl: string;

  constructor(
    config: ConfigService,
    private http: HttpClient
  ) {
    this.domain = `${environment.urls.domains.api[config.ENV]}/${environment.urls.versions.v1}`;
    this.baseUrl = `${this.domain}/${this.path}`;
    this.checkUserLoginStatus();
  }

  async checkUserLoginStatus() {
    try {
      let user = null;
      if (await this.getMe()) {
        user = await this.me().toPromise();
      }
      await this.setMe(user);
    } catch (e) {
      console.log(e);
    }
  }

  public getUserListener(): Observable<any> {
    return this._userListener.asObservable();
  }

  public async getMe(): Promise<any> {
    try {
      return LocalStorage.getObject(this._USER_KEY);
    } catch (e) {
      return null;
    }
  }

  public async setMe(user?: any) {
    if (user) {
      await LocalStorage.addObject(this._USER_KEY, user);
    } else {
      await LocalStorage.removeItem(this._USER_KEY);
    }
    this._userListener.next(user);
  }

  public async setReferral(username?: string) {
    if (username) {
      await LocalStorage.addItem('referrer', username);
    } else {
      await LocalStorage.removeItem('referrer');
    }
  }

  public async getReferral(): Promise<string> {
    return await LocalStorage.getItem('referrer');
  }

  /**
   * @todo - save to database on signup/login
   * @param hash
   */
  public async setDeviceHash(hash?: string) {
    if (hash) {
      await LocalStorage.addItem(this._HASH_KEY, hash);
    } else {
      await LocalStorage.removeItem(this._HASH_KEY);
    }
  }

  public async getDeviceHash(): Promise<string> {
    return await LocalStorage.getItem(this._HASH_KEY);
  }

  public async getUUID(user?: any) {
    let id: string;
    if (user) {
      id = user._id;
    } else {
      id = await this.getDeviceHash();
    }
    const now = Date.now();
    return `${id}-${now}`;
  }

  /**
   * API Routes for the User
   */

  public me(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/me`);
  }

  public searchUser(query: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/search`, { params: query });
  }

  public update(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, user);
  }

  public addPhone(id: string, phone: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/add-phone`, phone);
  }

  public verifyPhone(id: string, body: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${id}/verify-phone`, body);
  }
}
