import { AbstractModel } from '../abstract.model';
import {
  MenuItemType,
  PricingStrategy,
} from '../../enums';
import { BaseModelData } from '../../interfaces';
import { PriceOptionData } from './price-option';
import { MenuItemStationData } from './menu-item-station';

export interface ModifierGroupOptionData {
  _id: string;
  name: string;
  posName: string;
  product: string | Record<string, any>;
  taxCodes: string[];
  pricingStrategy: PricingStrategy;
  priceOptions: PriceOptionData[];
  isActive: boolean;
}

export interface ModifierGroupData extends BaseModelData {
  name: string;
  type: MenuItemType;
  options: ModifierGroupOptionData[];
  stations: MenuItemStationData['_id'][] | MenuItemStationData[];
  onlyOne: boolean;
  isRequired: boolean;
  /**
   * The number a customer can select
   * - this also works for multiple so its the sum of all mod.quantity
   */
  minQuantity: number;
  maxQuantity: number;
  multiple: boolean;
}

export class ModifierGroup extends AbstractModel<ModifierGroupData> {
  constructor(public data: ModifierGroupData) {
    super(data);
  }
}
